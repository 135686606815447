<script>
import { required } from "vuelidate/lib/validators";
import { parseErrors } from '../../../helpers'
import axios from 'axios';
import {
  authMethods,
  notificationMethods
} from "@/state/helpers";

export default {
  data() {
    return {
      userName: "",
      password: "",
      submitted: false,
      errors:[],
      tryingToLogIn: false,
      fpEmail: null,
      fpModal: null,
      sendingResetPasswordInstructions: false,
      resetPasswordRequestSubmitted: false
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    isFxHedge() {
      return process.env.VUE_APP_HEDGE === 'yes';
    },
    isVH() {
      return process.env.VUE_APP_VH === 'yes';
    },
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  validations: {
    userName: { required },
    password: { required }
  },
  methods: {
    ...authMethods,
    ...notificationMethods,
    onForgotPassword() {
      this.fpEmail = null;
      this.fpModal = true;
      this.resetPasswordRequestSubmitted = false;
    },
    async submitResetPassswordRequest() {
        this.sendingResetPasswordInstructions = true;
        this.resetPasswordRequestSubmitted = false;
        try {
          const url = `${process.env.VUE_APP_PUBLIC_USR_MNGM_URL}users/fx-hedge/forgot-password`;
          await axios.post(url, {
            email: this.fpEmail
          });
        } finally {
          this.sendingResetPasswordInstructions = false;
          this.resetPasswordRequestSubmitted = true;
        }
    },
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
          this.tryingToLogIn = true;
        
          this.errors = [];
          const { userName, password } = this;
          if (userName && password) {
            this.logIn({ userName, password })
            .then(() => {
                this.tryingToLogIn = false;
              
                this.$router.push(
                  this.$route.query.redirectFrom || { name: "home" }
                );
              })
              .catch(error => {
                  this.errors= parseErrors(error);
                  this.tryingToLogIn = false; 
              });
          }
      }
    }
  }
};
</script>

<template>
  <div>
    
    <div  class="container p-0 ">
      <div>
         <div class="gr-bg text-center p-4 mt-4 mb-4 border-radius" data-aos="fade-right">
                <div class="row">
                    <div class="col-xl-4 col-lg-3 col-md-2">

                    </div>
                    <div class="col-xl-4 col-lg-6  col-md-8" style="position: relative">
                        <img src="@/assets/images/star1.png" class="img-fluid spin-image"/>
                        <span style="position: absolute; left: 0; right: 0; top: 0; bottom: 0;display: flex;justify-content: center;align-items: center;" class="text-center">
                            <img src="@/assets/images/logo_white_hedge.png" alt="LOGO" class="img-fluid " style=" height: 60px;" v-if="isFxHedge"/>
                            <img src="@/assets/images/logo-vh-white-vert.png" alt="LOGO" class="img-fluid  mt-2 mb-4" style=" height: 130px;" v-else-if="isVH"/>
                            <img src="@/assets/images/c8-logo-w.svg" alt="LOGO" class="img-fluid " style=" height: 50px;" v-else/>
                        </span>
                    </div>
                    <div class="col-xl-4  col-lg-3  col-md-2">

                    </div>
                </div>
                   
            </div>
         <b-alert
            variant="danger"
            class="mt-3 mb-3"
            v-if="errors.length > 0"
            show
            dismissible
          >
          <p>
            The user name or password is incorrect.
          </p>
        </b-alert>
        <form class="form-horizontal mt-4" @submit.prevent="tryToLogIn">
          <div class="row">
            <div class="col-md-6" v-if="isFxHedge">
              <div class="form-group auth-form-group-custom mb-4">
                <i class="ri-mail-line auti-custom-input-icon"></i>
                <label for="email">Email</label>
                <input
                  type="text"
                  v-model="userName"
                  class="form-control"
                  id="email"
                  placeholder=""
                  :class="{ 'is-invalid': submitted && $v.userName.$error }"
                />
                <div v-if="submitted && $v.userName.$error" class="invalid-feedback">
                  <span v-if="!$v.userName.required">Email is required field</span>
                </div>
              </div>
            </div>
            <div class="col-md-6" v-else>
              <div class="form-group auth-form-group-custom mb-4">
                <i class="ri-mail-line auti-custom-input-icon"></i>
                <label for="userName">{{$t("pages.login.userName")}}</label>
                <input
                
                  v-model="userName"
                  class="form-control"
                  id="userName"
                  placeholder=""
                  :class="{ 'is-invalid': submitted && $v.userName.$error }"
                />
                <div v-if="submitted && $v.userName.$error" class="invalid-feedback">
                  <span v-if="!$v.userName.required">{{$t("pages.login.userName-required")}}</span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group auth-form-group-custom mb-4">
                <i class="ri-lock-2-line auti-custom-input-icon"></i>
                <label for="userpassword">{{$t("pages.login.password")}}</label>
                <input
                  v-model="password"
                  type="password"
                  class="form-control"
                  id="userpassword"
                  placeholder=""
                  :class="{ 'is-invalid': submitted && $v.password.$error }"
                />
                <div
                  v-if="submitted && !$v.password.required"
                  class="invalid-feedback"
                >{{$t("pages.login.password-required")}}</div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="d-flex align-items-middle mt-4 justify-content-center">
                <button
                  class="btn btn-primary w-md waves-effect waves-light"
                  type="submit"
                >
                <i class="fa fa-spin fa-spinner" v-if="tryingToLogIn"></i>
                {{$t("pages.login.login-button-title")}}</button>
              </div>
              <div v-if="isFxHedge" class="d-flex mt-4 justify-content-center">
                
                <span>
                  Or <a href='/register'>register</a> if you don't have an account yet.
                </span>
              
              
              </div>
              <div v-if="isFxHedge" class="d-flex mt-4 justify-content-center">
                
                <span>
                  <a href='javascript:void(0)' @click="onForgotPassword">I forgot my password.</a>
                </span>
              
              
              </div>
            </div>
          </div>
        </form>
        
      </div>
    </div>

    <b-modal
      id="modal-1"
      size="md"
      v-model="fpModal"
      title="Forgot Password? 🔒"
      title-class="text-dark font-18"
      hide-footer
    >
      <div autocomplete="off">
        <div v-if="resetPasswordRequestSubmitted">
          <p class="alert alert-success">
            We have sent a email with instructions to reset your password. If you do not see the email in a few minutes, check your “junk mail” folder or “spam” folder. We make every effort to ensure that these emails are delivered. 
          </p>
        </div>
        <div v-else>
          <p class="px-2 py-2">
            Enter your email and we'll send you instructions to reset your password.
          </p>

          <div class="form-group row">
            <label class="col-lg-3  text-right" for="email">Your Email</label>
            <div class="col-lg-9">
              <input
                id="email"
                v-model="fpEmail"
                type="email"
                class="form-control"
              />
            </div>
        </div>
        
        </div>

        <div>
          <b-button class="ml-1 float-left" @click="fpModal = false">Close</b-button>
          <button v-if="!resetPasswordRequestSubmitted" type="button" class="btn btn-success float-right" :disabled="sendingResetPasswordInstructions || !fpEmail" @click="submitResetPassswordRequest">
            <i class="fa fa-spin fa-spinner" v-if="sendingResetPasswordInstructions"></i>
            Reset Password
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>